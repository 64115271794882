<template>
  <v-container class="mt-5" v-if="sportangebot">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="auto" align-self="center">
            <v-btn x-large rounded icon to="/admin/sportangebote" exact>
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col align-self="center">
            <h1 v-if="sportangebot.name">"{{ sportangebot.name }}" bearbeiten</h1>
            <h1 v-if="!sportangebot.name">Sportangebot erstellen</h1>
          </v-col>
          <v-col cols="auto" align-self="center">
            <v-btn large rounded text color="red" @click="remove">
              <v-icon>mdi-delete</v-icon>
              Löschen
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="sportangebot.approved == false && sportangebot.author != 'Admin'">
        Dieses Angebot wurde über das Online-Formular des Vereins {{sportangebot.verein.name}} am {{getDate(sportangebot.created_at)}} eingereicht.<br>
      </v-col>
      <v-col cols="12" v-if="sportangebot.approved == true && sportangebot.author != 'Admin'">
        Dieses Angebot wurde über das Online-Formular des Vereins {{sportangebot.verein.name}} am {{getDate(sportangebot.created_at)}} eingereicht.<br>
      </v-col>
      <v-col cols="12">
        <v-card light class="rounded-xl pa-4">
          <v-row>
            <v-col cols="12">
              <v-btn 
                v-if="sportangebot.approved && sportangebot.active"
                rounded
                color="error" 
                class="mt-3 mr-2"
                @click="
                  sportangebot.active = false
                  update()
                "
              >
                <font-awesome-icon icon="fa-solid fa-ban" class="mr-2" size="lg" /> Angebot deaktivieren
              </v-btn> 
              <v-btn 
                v-if="sportangebot.approved && !sportangebot.active"
                rounded
                color="success" 
                class="mt-3 mr-2"
                @click="
                  sportangebot.active = true
                  update()
                "
              >
                <font-awesome-icon icon="fa-solid fa-check" size="lg" class="mr-2" /> Angebot aktivieren
              </v-btn> 
              <v-btn 
                v-if="!sportangebot.approved"
                rounded 
                color="success" 
                class="mt-3"
                @click="
                  sportangebot.active = true 
                  sportangebot.approved = true 
                  update()
                "
                :disabled="sportangebot.approved"
              >
                <font-awesome-icon icon="fa-duotone fa-circle-check" size="lg" class="mr-2" /> {{sportangebot.approved ? 'Angebot genehmigt' : 'Angebot genehmigen'}}
              </v-btn> 
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field
                filled
                rounded
                label="Name"
                v-model="sportangebot.name"
                hide-details
                @change="update"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6">
              <v-select
                :items="vereine.data"
                item-value="id"
                item-text="name"
                offset-y
                filled
                rounded
                hide-details
                label="Verein"
                prepend-inner-icon="mdi-shield-outline"
                v-model="sportangebot.verein"
                @change="update"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-textarea
                filled
                rounded
                hide-details
                label="Beschreibung"
                v-model="sportangebot.beschreibung"
                @change="update"
              ></v-textarea>
            </v-col>
            <v-col cols="12" lg="4">
              <v-select
                :items="sportarten.data"
                item-value="id"
                item-text="name"
                offset-y
                filled
                rounded
                hide-details
                label="Sportart"
                v-model="sportangebot.sportart"
                @change="update"
              ></v-select>
            </v-col>
            <v-col cols="12" lg="4">
              <v-select
                :items="altersgruppen"
                offset-y
                filled
                rounded
                hide-details
                multiple
                label="Altersgruppe(n)"
                v-model="sportangebot.altersgruppen"
                @change="update"
              ></v-select>
            </v-col>
            <v-col cols="12" lg="4">
              <v-select
                :items="geschlecht"
                offset-y
                filled
                rounded
                hide-details
                label="Geschlecht"
                prepend-inner-icon="mdi-gender-male-female"
                v-model="sportangebot.geschlecht"
                @change="update"
              ></v-select>
            </v-col>
            <v-col cols="12" lg="4">
              <v-select
                :items="teilnehmerzahl"
                offset-y
                filled
                rounded
                hide-details
                label="Maximale Teilnehmerzahl"
                prepend-inner-icon="mdi-account-group"
                v-model="sportangebot.teilnehmerzahl"
                @change="update"
              ></v-select>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-5" justify="center">
      <v-col cols="12">
        <v-card light class="rounded-xl pa-4">
          <v-row>
            <v-col cols="12">
              <h3>Medien</h3>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="4"
              align-self="center"
              v-for="(media, index) in sportangebot.fotos"
              :key="index"
            >
              <v-card
                color="secondary"
                class="rounded-xl pa-0 ma-4"
                style="min-height: 200px;"
              >
                <v-row justify="center" style="height: 100%;">
                  <v-col cols="6" align-self="center" v-if="media.img">
                    <v-img :src="media.img" width="100%"></v-img>
                  </v-col>
                  <v-col cols="8" align-self="center">
                    <v-btn
                      block
                      rounded
                      :text="!media.img"
                      :color="media.img ? 'primary' : 'white'"
                      @click.stop="media.dialog = true"
                    >
                      <v-icon>
                        {{ media.img ? 'mdi-change' : 'mdi-upload' }}
                      </v-icon>
                      {{ media.img ? 'BILD ÄNDERN' : 'BILD HOCHLADEN' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
              <v-dialog
                v-model="media.dialog"
                :max-width="$vuetify.breakpoint.mdAndUp ? '1200' : 'none'"
                :overlay-opacity="
                  $vuetify.breakpoint.mdAndUp ? 0.85 : undefined
                "
                :hide-overlay="$vuetify.breakpoint.mdAndUp ? false : true"
                :transition="
                  $vuetify.breakpoint.mdAndUp
                    ? 'dialog-transition'
                    : 'dialog-bottom-transition'
                "
                :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true"
                :width="$vuetify.breakpoint.mdAndUp ? 'auto' : '100vw'"
                content-class="rounded-xl"
              >
                <v-card class="rounded-xl">
                  <v-toolbar color="primary" dark>
                    <v-btn icon @click="closeDialog(index)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Logo hochladen</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                        dark
                        text
                        :loading="media.uploading"
                        @click="upload(index)"
                      >
                        Speichern
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-card-text>
                    <v-row justify="center" class="my-4">
                      <v-col :cols="media.imageData ? 12 : 10">
                        <v-image-input
                          v-model="media.imageData"
                          :image-quality="0.85"
                          clearable
                          accept="image/*"
                          :hideActions="media.imageData ? false : true"
                          imageWidth="800"
                          :imageMinScaling="'cover'"
                          imageHeight="600"
                          :full-width="true"
                          image-format="png"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        class="text-center"
                        v-if="!media.imageData"
                      >
                        Für eine gute Darstellung wähle bitte ein Bild mit
                        mindestens 600 x 800 Pixeln.
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions right>
                    <v-btn
                      color="primary"
                      right
                      rounded
                      :loading="media.uploading"
                      @click="upload(index)"
                    >
                      Speichern
                    </v-btn>
                    <v-btn
                      color="secondary"
                      right
                      rounded
                      @click="closeDialog(index)"
                    >
                      Abbrechen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-btn
                rounded
                color="primary"
                @click="
                  sportangebot.fotos.push({
                    imageData: '',
                    titelbild: false,
                    uploading: false,
                    dialog: true,
                    img: '',
                  })
                "
              >
                <v-icon>mdi-plus</v-icon>
                bild Hinzufügen
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-5" justify="center">
      <v-col cols="12">
        <v-card light class="rounded-xl pa-4">
          <v-row>
            <v-col cols="12">
              <h3>Zeitraum</h3>
            </v-col>
            <v-col cols="12">
              <v-select
                filled
                rounded
                label="Wiederholung"
                :items="repeat"
                v-model="sportangebot.wiederholung"
                hide-details
                @change="update"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              v-if="sportangebot.wiederholung == 'Einmaliger Termin'"
            >
              <v-row v-if="sportangebot.termin">
                <v-col cols="12">
                  <v-text-field
                    filled
                    rounded
                    hide-details
                    label="Datum"
                    v-model="sportangebot.termin.datum"
                    prepend-inner-icon="mdi-calendar"
                    @change="update"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg>
                  <v-text-field
                    filled
                    rounded
                    label="Beginn"
                    hide-details
                    v-model="sportangebot.termin.beginn"
                    prepend-inner-icon="mdi-clock"
                    @change="update"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="auto"
                  v-if="$vuetify.breakpoint.lgAndUp"
                  align-self="center"
                >
                  bis
                </v-col>
                <v-col cols="12" lg>
                  <v-text-field
                    filled
                    rounded
                    label="Ende"
                    hide-details
                    v-model="sportangebot.termin.ende"
                    prepend-inner-icon="mdi-clock"
                    @change="update"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              v-if="sportangebot.wiederholung == 'Mehrere Termine'"
            >
              <v-row
                v-for="(termin, index) in sportangebot.termine"
                :key="index"
              >
                <v-col cols="12" class="font-weight-bold">
                  <v-row justify="space-between">
                    <v-col cols="auto">Termin {{ index + 1 }}:</v-col>
                    <v-col cols="auto">
                      <v-btn
                        icon
                        @click="
                          removeTermin(index)
                          update
                        "
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    filled
                    rounded
                    hide-details
                    label="Datum"
                    v-model="termin.datum"
                    prepend-inner-icon="mdi-calendar"
                    @change="update"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg>
                  <v-text-field
                    filled
                    rounded
                    label="Beginn"
                    v-model="termin.beginn"
                    hide-details
                    prepend-inner-icon="mdi-clock"
                    @change="update"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="auto"
                  v-if="$vuetify.breakpoint.lgAndUp"
                  align-self="center"
                >
                  bis
                </v-col>
                <v-col cols="12" lg>
                  <v-text-field
                    filled
                    rounded
                    label="Ende"
                    v-model="termin.ende"
                    hide-details
                    prepend-inner-icon="mdi-clock"
                    @change="update"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="auto">
                  <v-btn
                    color="primary"
                    rounded
                    @click="
                      sportangebot.zeitraum.push({
                        datum: '',
                        beginn: '',
                        ende: '',
                      })
                      update
                    "
                  >
                    <v-icon>mdi-plus</v-icon>
                    Termin hinzufügen
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              v-if="sportangebot.wiederholung == 'Regelmäßige Termine'"
            >
              <v-row
                v-for="(termin, index) in sportangebot.zeitraum"
                :key="index"
              >
                <v-col cols="12" class="font-weight-bold">
                  <v-row justify="space-between">
                    <v-col cols="auto">Termin {{ index + 1 }}:</v-col>
                    <v-col cols="auto">
                      <v-btn
                        icon
                        @click="
                          removeTermin(index)
                          update
                        "
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-select
                    filled
                    rounded
                    hide-details
                    label="Wochentag"
                    v-model="termin.wochentag"
                    :items="[
                      'Montag',
                      'Dienstag',
                      'Mittwoch',
                      'Donnerstag',
                      'Freitag',
                      'Samstag',
                      'Sonntag',
                    ]"
                    prepend-inner-icon="mdi-calendar"
                    @change="update"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    filled
                    rounded
                    hide-details
                    label="Von Datum"
                    v-model="termin.datum.von"
                    prepend-inner-icon="mdi-calendar"
                    @change="update"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    filled
                    rounded
                    hide-details
                    label="Bis Datum"
                    v-model="termin.datum.bis"
                    prepend-inner-icon="mdi-calendar"
                    @change="update"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg>
                  <v-text-field
                    filled
                    rounded
                    label="Beginn"
                    v-model="termin.uhrzeit.beginn"
                    hide-details
                    prepend-inner-icon="mdi-clock"
                    @change="update"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="auto"
                  v-if="$vuetify.breakpoint.lgAndUp"
                  align-self="center"
                >
                  bis
                </v-col>
                <v-col cols="12" lg>
                  <v-text-field
                    filled
                    rounded
                    label="Ende"
                    v-model="termin.uhrzeit.ende"
                    hide-details
                    prepend-inner-icon="mdi-clock"
                    @change="update"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="auto">
                  <v-btn
                    color="primary"
                    rounded
                    @click="
                      sportangebot.zeitraum.push({
                        datum: '',
                        beginn: '',
                        ende: '',
                      })
                      update
                    "
                  >
                    <v-icon>mdi-plus</v-icon>
                    Termin hinzufügen
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-5" justify="center">
      <v-col cols="12">
        <v-card light class="rounded-xl pa-4">
          <v-row>
            <v-col cols="12">
              <h3>Veranstaltungsort</h3>
            </v-col>
            <v-col cols="12" v-if="sportangebot.veranstaltungsort">
              <v-text-field
                filled
                rounded
                label="Name des Veranstaltungsortes"
                prepend-inner-icon="mdi-office-building-marker"
                v-model="sportangebot.veranstaltungsort.name"
                hide-details
                @change="update"
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="sportangebot.veranstaltungsort">
              <v-text-field
                filled
                rounded
                label="Adresse Veranstaltungsortes"
                prepend-inner-icon="mdi-map-marker"
                v-model="sportangebot.veranstaltungsort.adresse"
                hide-details
                @change="update"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" v-if="sportangebot.veranstaltungsort">
              <v-text-field
                filled
                rounded
                label="Postleitzahl"
                prepend-inner-icon="mdi-map-marker"
                v-model="sportangebot.veranstaltungsort.plz"
                hide-details
                @change="update"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="8" v-if="sportangebot.veranstaltungsort">
              <v-text-field
                filled
                rounded
                label="Ort"
                prepend-inner-icon="mdi-home-map-marker"
                v-model="sportangebot.veranstaltungsort.ort"
                hide-details
                @change="update"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-5" justify="center">
      <v-col
        cols="12"
        v-for="(ansprechpartner, index) in sportangebot.ansprechpartner"
        :key="index"
      >
        <v-card light class="rounded-xl pa-4">
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col>
                  <h3>
                    {{ index > 0 ? index + 1 + '. ' : '' }}Ansprechpartner
                  </h3>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    icon
                    color="danger"
                    v-if="sportangebot.ansprechpartner.length > 1"
                    @click="removeAnsprechpartner(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field
                rounded
                filled
                v-model="ansprechpartner.vorname"
                label="Vorame"
                hide-details
                @change="update"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field
                rounded
                filled
                v-model="ansprechpartner.nachname"
                label="Nachame"
                hide-details
                @change="update"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field
                rounded
                filled
                v-model="ansprechpartner.funktion"
                label="Funktion (z.B. Trainer | optional)"
                hide-details
                @change="update"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field
                rounded
                filled
                v-model="ansprechpartner.email"
                label="E-Mail"
                hide-details
                @change="update"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field
                rounded
                filled
                v-model="ansprechpartner.telefon"
                label="Telefon (optional)"
                hide-details
                @change="update"
              ></v-text-field>
              <v-checkbox color="primary" hide-details v-model="ansprechpartner.telefonpublic" label="Telefonnummer darf öffentlich angezeigt werden"></v-checkbox>
              <v-checkbox v-if="ansprechpartner.telefonpublic" color="primary" hide-details v-model="ansprechpartner.whatsapp" label="Telefonnummer kann per WhatsApp kontaktiert werden"></v-checkbox>  
            </v-col>
            <v-col cols="12" lg="6">
              <span class="ml-3" v-if="emailVerified(ansprechpartner)"><font-awesome-icon class="green--text mr-2" icon="fa-solid fa-check-double" />E-Mail wurde verifiziert.</span>
            </v-col>
            <v-col
              cols="12"
              v-if="index + 1 == sportangebot.ansprechpartner.length"
              class="text-center"
            >
              <v-btn
                rounded
                color="success"
                @click="
                  sportangebot.ansprechpartner.push({
                      vorname: '',
                      nachname: '',
                      telefon: '',
                      telefonpublic: false,
                      whatsapp: false,
                      email: '',
                      emailvalid: false,
                      funktion: '',
                  })
                  update
                "
              >
                <v-icon class="mr-2">mdi-plus</v-icon>
                Hinzufügen
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-5" justify="center">
      <v-col cols="12">
        <v-card light class="rounded-xl pa-4">
          <v-row>
            <v-col cols="12">
              <h3>
                Material, das Teilnehmer mitbringen müssen
              </h3>
            </v-col>
            <v-col cols="12">
              <v-row
                  justify="center"
                  v-for="(material, index) in sportangebot.material"
                  :key="index"
              >
                  <v-col cols="2" align-self="center" class="primary--text text-center">
                      <v-icon color="primary" v-if="material.iconset == 'mdi'" large>mdi-{{material.icon}}</v-icon>
                      <font-awesome-icon v-if="material.iconset == 'fa'" size="lg" :icon="material.icon" />   
                  </v-col>
                  <v-col align-self="center">
                      <v-text-field
                          filled
                          rounded
                          label="Bezeichnung"
                          v-model="material.name"
                          hide-details
                          @change="update()"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="auto" align-self="center">
                      <v-btn 
                        icon 
                        @click="
                          removeMaterial(index)
                          update()
                        "
                      >
                          <v-icon>mdi-delete</v-icon>
                      </v-btn>
                  </v-col>
              </v-row>
              </v-col>
              <v-col cols="12" class="text-center">
                  <v-btn
                      color="primary"
                      rounded
                      @click="addMaterial = true"
                  >
                      <v-icon>mdi-plus</v-icon>
                      Hinzufügen
                  </v-btn>
              </v-col>
              <v-dialog content-class="rounded-xl" v-model="addMaterial" max-width="700">
                  <v-card class="rounded-xl">
                      <v-row justify="space-around" class="pa-5">
                          <v-col cols="12" class="text-center">
                              <h2>Wähle eine Vorlage aus</h2>
                          </v-col>
                          <v-col v-for="m in material" :key="m.name" cols="6" md="4">
                              <v-card 
                                  color="#b6b4c8" 
                                  hover 
                                  class="rounded-xl primary--text text-center pa-4 h-100" 
                                  @click="
                                      sportangebot.material.push(m)
                                      addMaterial = false
                                      update()
                                  ">
                                  <v-icon x-large color="primary" v-if="m.iconset == 'mdi'">mdi-{{m.icon}}</v-icon><br v-if="m.iconset == 'mdi'">
                                  <font-awesome-icon v-if="m.iconset == 'fa'" size="xl" :icon="m.icon" /><br v-if="m.iconset == 'fa'">
                                  <h3 class="my-2">{{m.name}}</h3>
                              </v-card>
                          </v-col>
                      </v-row>
                  </v-card>
              </v-dialog> 
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { doc, updateDoc, deleteDoc, Timestamp } from 'firebase/firestore'
import router from '../../../router'
import md5 from 'md5'

export default {
  name: 'sportangebot',
  data() {
    return {
      addMaterial: false,
      sportangebot: '',
      vdoc: '',
      altersgruppen: [
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
      ],
      teilnehmerzahl: [],
      repeat: ['Einmaliger Termin', 'Mehrere Termine', 'Regelmäßige Termine'],
      geschlecht: ['Nur für Jungen', 'Nur für Mädchen', 'Für Alle'],
      menu: {
        einmalig: {
          datum: false,
          beginn: false,
          ende: false  
        },
        regelmaessig: [
            {
                vondatum: false,
                bisdatum: false,
                beginn: false,
                ende: false
            }
        ],
        mehrere: [
            {
                datum: false,
                beginn: false,
                ende: false
            },
            {
                datum: false,
                beginn: false,
                ende: false
            }
        ]
      },
      material: [
          {
              name: 'Sportschuhe',
              icon: 'shoe-sneaker',
              iconset: 'mdi'
          },
          {
              name: 'Hallenschuhe',
              icon: 'shoe-sneaker',
              iconset: 'mdi'
          },
          {
              name: 'Laufschuhe',
              icon: 'shoe-sneaker',
              iconset: 'mdi'
          },
          {
              name: 'Fussballschuhe',
              icon: 'shoe-cleat',
              iconset: 'mdi'
          },
          {
              name: 'Sportshirt',
              icon: 'fa-light fa-shirt',
              iconset: 'fa'
          },
          {
              name: 'Sportjacke',
              icon: 'fa-solid fa-shirt-long-sleeve',
              iconset: 'fa'
          },
          {
              name: 'Pullover',
              icon: 'fa-solid fa-shirt-long-sleeve',
              iconset: 'fa'
          },
          {
              name: 'Sporthose',
              icon: 'fa-light fa-clothes-hanger',
              iconset: 'fa'
          },
          {
              name: 'Schwimmkleidung',
              icon: 'fa-light fa-clothes-hanger',
              iconset: 'fa'
          },
          {
              name: 'Handtuch',
              icon: 'fa-light fa-rectangle-vertical',
              iconset: 'fa'
          },
          {
              name: 'Maske',
              icon: 'fa-light fa-mask-face',
              iconset: 'fa'
          },
          {
              name: 'Trinkflasche',
              icon: 'fa-regular fa-droplet',
              iconset: 'fa'
          },
          {
              name: 'Tischtennis-Schläger',
              icon: 'fa-light fa-table-tennis-paddle-ball',
              iconset: 'fa'
          },
          {
              name: 'Tennis-Schläger',
              icon: 'fa-light fa-racquet',
              iconset: 'fa'
          },
          {
              name: 'Badminton-Schläger',
              icon: 'fa-light fa-badminton',
              iconset: 'fa'
          },
          {
              name: 'Weitere',
              icon: 'fa-light fa-plus',
              iconset: 'fa'
          }
      ],
    }
  },
  mounted() {
    this.setSportangebot()
    this.teilnehmerzahl = []
    for (var i = 1; i <= 100; i++) {
      this.teilnehmerzahl.push(i)
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      sportarten: 'sportarten',
      sportangebote: 'sportangebote',
      db: 'db',
    }),
  },
  methods: {
    emailVerified(ansprechpartner){
      if(this.sportangebot.mailingliste?.includes(md5(ansprechpartner.email))){
        return true
      }
      return false
    },
    update() {
      var angebot = this.sportangebot
      angebot.verein = this.sportangebot.verein?.id || this.sportangebot.verein
      angebot.sportart =
        this.sportangebot.sportart?.id || this.sportangebot.sportart
      updateDoc(this.vdoc, angebot)
    },
    async remove() {
      const deletion = await deleteDoc(
        doc(this.db, 'Sportangebote', this.sportangebot.id),
      )
      router.push('/admin/sportangebote')
    },
    setSportangebot() {
      this.sportangebot = this.sportangebote.all.find(
        (angebot) => angebot.id === this.$route.params.sportangebot,
      )

      if (this.sportangebot) {
        this.vdoc = doc(this.db, 'Sportangebote', this.sportangebot.id)
        this.sportangebot.fotos.forEach(foto => {
          foto.imageData = foto.img
        })
      } else {
        setTimeout(() => {
          this.setSportangebot()
        }, 50)
      }
    },
    removeTermin(id) {
      var neue_termine = []
      this.sportangebot.termine.forEach((termin, index) => {
        if (index != id) {
          neue_termine.push(termin)
        }
      })
      this.sportangebot.termine = neue_termine
    },
    removeZeitraum(id) {
      var neuer_zeitraum = []
      this.sportangebot.zeitraum.forEach((termin, index) => {
        if (index != id) {
          neuer_zeitraum.push(termin)
        }
      })
      this.sportangebot.zeitraum = neuer_zeitraum
    },
    removeAnsprechpartner(id) {
      var neue_ansprechpartner = []
      this.sportangebot.ansprechpartner.forEach((person, index) => {
        if (index != id) {
          neue_ansprechpartner.push(person)
        }
      })
      this.sportangebot.ansprechpartner = neue_ansprechpartner
    },
    removeMaterial(id) {
      var neues_material = []
      this.sportangebot.material.forEach((material, index) => {
        if (index != id) {
          neues_material.push(material)
        }
      })
      this.sportangebot.material = neues_material
      this.update()
    },
    closeDialog(index) {
      this.sportangebot.fotos[index].dialog = false
      this.sportangebot.fotos[index].uploading = false
      this.sportangebot.fotos[index].imageData = ''
    },
    upload(index) {
      if (this.sportangebot.fotos[index].imageData) {
        this.sportangebot.fotos[index].img = this.sportangebot.fotos[
          index
        ].imageData
        this.sportangebot.fotos[index].uploading = false
        this.sportangebot.fotos[index].imageData = ''
        this.sportangebot.fotos[index].dialog = false
        this.update()
      }
    },
    getDate(date){
      if(date instanceof Timestamp){
        var datum = date.toDate()
        return datum.getDate().toString().padStart(2, '0')+'.'+datum.getMonth().toString().padStart(2, '0')+'.'+datum.getFullYear()
      }
      return date
    }
  },
}
</script>
